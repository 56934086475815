.contentSection.contentSectionTwoCols {
  background-color: $color-very-light-grey;

  .contentSectionContainer {
    padding: 7.5vw 10vw;

    .contentSectionContent {
      font-size: $font-size-bigger;
      margin: 2rem 0rem;

      strong {
        color: $color-main;
      }
    }

    a.buttonLink {
      min-width: 150px;
      text-align: center;
    }

    ul {
      font-size: $font-size-bigger;
      list-style-type: none;
      margin: 0px;
      padding: 0px;

      li {
        padding-left: 1rem;
        text-indent: -1rem;

        &:before {
          content: "\25E6";
          font-family: "fontawesome";
          color: $color-main;
          width: 1rem;
          font-weight: bolder;
          margin-right: 0.25rem;
        }
      }
    }
  }
}
