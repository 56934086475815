@font-face {
  font-family: 'Neo Sans Std';
  src: url('../fonts/NeoSansStd-Regular.woff2') format('woff2'),
  url('../fonts/NeoSansStd-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neo Sans Std';
  src: url('../fonts/NeoSansStd-Bold.woff2') format('woff2'),
  url('../fonts/NeoSansStd-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

h1, h2, h3, h4 {
  font-family: $neo-sans;
  font-weight: normal;
}

b, strong {
  font-family: $neo-sans;
  font-weight: bold;
}

p {
  margin-bottom: 0px;
  line-height: 1.65;
}

body {
  font-family: $neo-sans;
  font-size: $font-size-body;
  line-height: 1.5rem;
  color: $color-dark-grey;
}

i.fab, i.fas, i.far {
  font-family: FontAwesome !important;
  font-style: normal;
}
