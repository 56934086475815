.homepage-header {
  @include media-breakpoint-up(lg) {
    @include keepaspectratio();
  }

  @include media-breakpoint-only(md) {
    @include keepaspectratio(75%);
  }

  @include media-breakpoint-down(sm) {
    @include keepaspectratio(100%);
  }

  position: relative;
  max-height: 70vh;
  background: $default-gradient-transparent;
  overflow: hidden;

  .homepage-header-slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
    background-position: center;

    .slick-list {
      height: 100%;

      .slick-track {
        height: 100%;

        .homepage-header-slider-single {
          background-size: cover;
          background-position: right center;
        }
      }
    }
  }

  .homepage-header-inner {
    color: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;

    .homepage-header-logo {
      width: 30%;
      min-width: 100px;
      max-width: 100%;
      margin-bottom: 1rem;

      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    .homepage-header-headline {
      @include media-breakpoint-down(xs) {
        font-size: 5vw;
        margin-top: 2rem;
      }
    }

    .homepage-header-subheadline {
      opacity: 0.75;
      margin-bottom: 1.5rem;

      @include media-breakpoint-down(xs) {
        font-size: 4vw;
        margin-bottom: 1rem;
      }
    }
  }
}

.benefitCards {
  margin-top: 2rem;
  margin-bottom: 8rem;
  max-width: 1140px;

  .row {
    transform: translate(0,0);
  }

  @include media-breakpoint-up(md) {
    margin-top: -3rem;
  }

  .singleBenefitCard {
    text-align: center;
    @include media-breakpoint-down(md) {
      margin-bottom: 3rem;
    }

    .card-outer {
      position: relative;
      height: 100%;
      display: flex;
      min-width: 0;
      word-wrap: break-word;
      background-clip: border-box;

      @include keepaspectratio(100%);

      .card-inner {
        transition: transform 0.6s;
        transform-style: preserve-3d;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        perspective: 300px;

        .card-front,
        .card-back {
          backface-visibility: hidden;
          padding: 15px;
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(0,0,0,0.125);
          border-radius: .25rem;
          background-color: $white;
          @include floating-boxshadow();
        }

        .card-front {
          transform: rotateY(0deg);

          .singleBenefitCardIcon {
            font-size: 2.5rem;
            line-height: 1;
            display: block;
            color: $color-main;
          }

          .singleBenefitCardHeadline {
            margin-top: 0.75rem;
            display: block;
            color: $color-main;
            font-size: $font-size-stronghead;
            font-weight: normal;
            hyphens: auto;
          }
        }

        .card-back {
          transform: rotateY(180deg);

          .singleBenefitCardText {
            line-height: 1.1;
            width: 100%;
            hyphens: auto;
          }
        }
      }

      &:hover {
        .card-inner {
          transform: rotateY(180deg);
        }
      }
    }
  }
}
