.jobs-section {
  padding: 4rem 0px;

  .jobs-section-col {
    &.jobs-section-col-left {
      @include media-breakpoint-down(md) {
        margin-bottom: 2rem;
      }
      @include media-breakpoint-up(lg) {
        padding-right: 2rem;
      }

      .jobsPersonsContainer {
        padding: 15px;
        margin-top: 16px;
        margin-bottom: 16px;

        .fas {
          font-size: 4rem;
          margin-right: 10px;

          &.fa-user-circle {
            color: $color-light-grey;
          }

          &.fa-question-circle {
            color: $color-main;
            font-size: 4.75rem;
          }
        }
      }
    }

    &.jobs-section-col-right {
      strong {
        color: $color-light-grey;
        font-size: $font-size-alt-head;
        font-weight: normal;
      }

      .singleJobOffer {
        margin: 2rem 0px;

        a.singleJobOfferHead {
          font-size: $font-size-stronghead;
          font-family: $neo-sans;
        }

        p.singleJobOfferDesc {
          margin-bottom: 0px;
          color: $color-dark-grey;
        }
      }

      a.initiativeLink {
        font-size: 1rem;
      }
    }
  }
}
