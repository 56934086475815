.benefitsContainer {
  margin-bottom: 8rem;
  margin-bottom: 8rem;
  @include media-breakpoint-up(sm) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .singleBenefit {
    text-align: center;
    @include media-breakpoint-down(md) {
      margin-bottom: 3rem;
    }

    .singleBenefitIcon {
      font-size: 4rem;
      line-height: 5rem;
      display: block;
      color: $color-main;
    }

    .singleBenefitHeadline {
      margin-top: 0.75rem;
      display: block;
      color: $color-main;
      font-size: $font-size-stronghead;
      font-weight: normal;
    }

    .singleBenefitText {
      display: block;
      color: $color-light-grey;
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
}
