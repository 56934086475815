.clientsContainerWrapper {
  padding: 4rem 0rem;
  @include media-breakpoint-up(sm) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  h2 {
    text-align: center;
  }

  .clientsContainer {
    margin-top: 3rem;

    .singleClient {
      transition: all 0.2s ease;
      padding: 15px;

      .clientSizer {
        @include defaulttransition();
        padding-top: 100%;
        border-radius: 5px;
        border: 1px solid $color-light-grey;
        background-position: center;
        background-size: 85% 85%;
        background-repeat: no-repeat;
        background-color: #fff;
        filter: grayscale(100%);

        &:hover {
          -webkit-filter: none;
          filter: none;
        }

        span.clientVisitLink {
          display: none;
          text-align: center;
          position: absolute;
          left: 1rem;
          right: 1rem;
          bottom: 1rem;
          line-height: 1.5rem;
          background-color: grey;
          color: white;
        }

        &:hover {
          span.clientVisitLink {
            display: block;
          }
        }
      }
    }
  }
}
