.headerImage {
  position: relative;
  width: 100%;
  background-position: center;
  background-size: cover;
  overflow: hidden;

  @include media-breakpoint-up(lg) {
    max-height: 40vh;

    @include keepaspectratio(30%);
  }

  @include media-breakpoint-down(md) {
    @include keepaspectratio(35%);
  }

  @include media-breakpoint-down(sm) {
    @include keepaspectratio(40%);
  }
}
