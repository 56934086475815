.contentSection.contentSectionSingle {
  background-color: $color-very-light-grey;

  .contentSectionContainer {
    padding-top: 10vw;
    padding-bottom: 10vw;

    .contentSectionHeadline {
      text-align: center;
    }

    .contentSectionContent {
      font-size: $font-size-bigger;
      margin: 2rem 0rem;

      strong {
        color: $color-main;
      }
    }

    a.buttonLink {
      min-width: 150px;
      text-align: center;
    }
  }
}
