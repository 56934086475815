.referenceHeaderImageSection,
.singleLastProject,
.singleOtherProject {
  background-color: $color-very-light-grey;

  .referenceHeaderImageSectionContainer,
  .singleLastProjectDevice,
  .singleOtherProjectDevice {
    padding-top: 3vw;
    padding-bottom: 0rem;

    .referenceHeaderImageSectionMonitorContainer {
      width: 100%;
      padding-top: 66.66%;
      position: relative;
      background-size: contain;
      background-position: bottom center;
      background-image: url(/assets/dist/img/monitor-iso.png);
      background-repeat: no-repeat;

      .referenceHeaderImageSectionMonitorInner {
        position: absolute;
        top: 8%;
        right: 7%;
        bottom: 24%;
        left: 7%;
        background-size: cover;
        background-position: top center;

        .referenceHeaderImageSectionMonitorSlider {
          height: 100%;

          .slick-track, .slick-list {
            height: 100%;
          }

          .referenceHeaderImageSectionMonitorSliderInner {
            background-size: cover;
            background-position: top center;
          }

          .slick-prev {
            position: absolute;
            top: 45%;
            left: -25%;
            font-size: 3rem;
            color: $color-main;
            cursor: pointer;
            @include media-breakpoint-down(sm) {
              left: -15%;
            }
            @include media-breakpoint-down(xs) {
              font-size: 2rem;
            }
          }

          .slick-next {
            position: absolute;
            top: 45%;
            right: -25%;
            font-size: 3rem;
            color: $color-main;
            cursor: pointer;
            @include media-breakpoint-down(sm) {
              right: -15%;
            }
            @include media-breakpoint-down(xs) {
              font-size: 2rem;
            }
          }
        }
      }
    }

    .referenceHeaderImageSectionPhoneContainer {
      width: 100%;
      padding-top: 66.66%;
      position: relative;
      background-size: contain;
      background-position: bottom center;
      background-image: url(/assets/dist/img/phone-iso.png);
      background-repeat: no-repeat;

      .referenceHeaderImageSectionPhoneInner {
        position: absolute;
        top: 12%;
        right: 37.5%;
        bottom: 18%;
        left: 37.5%;
        background-size: cover;
        background-position: top center;

        .referenceHeaderImageSectionPhoneSlider {
          height: 100%;

          .slick-track, .slick-list {
            height: 100%;
          }

          .referenceHeaderImageSectionPhoneSliderInner {
            background-size: cover;
            background-position: top center;
          }

          .slick-prev {
            position: absolute;
            top: 45%;
            left: -50%;
            font-size: 3rem;
            color: $color-main;
            cursor: pointer;
            @include media-breakpoint-down(xs) {
              font-size: 2rem;
            }
          }

          .slick-next {
            position: absolute;
            top: 45%;
            right: -50%;
            font-size: 3rem;
            color: $color-main;
            cursor: pointer;
            @include media-breakpoint-down(xs) {
              font-size: 2rem;
            }
          }
        }
      }
    }

    .referenceHeaderImageSectionTvContainer,
    {
      width: 100%;
      padding-top: 66.66%;
      position: relative;
      background-size: contain;
      background-position: bottom center;
      background-image: url(/assets/dist/img/tv-iso.png);
      background-repeat: no-repeat;

      .referenceHeaderImageSectionTvInner {
        position: absolute;
        top: 15%;
        right: 12%;
        bottom: 20%;
        left: 12%;
        background-size: cover;
        background-position: top center;

        .referenceHeaderImageSectionTvSlider {
          height: 100%;

          .slick-track, .slick-list {
            height: 100%;
          }

          .referenceHeaderImageSectionTvSliderInner {
            background-size: cover;
            background-position: top center;
          }

          .slick-prev {
            position: absolute;
            top: 45%;
            left: -25%;
            font-size: 3rem;
            color: $color-main;
            cursor: pointer;
            @include media-breakpoint-down(sm) {
              left: -15%;
            }
            @include media-breakpoint-down(xs) {
              font-size: 2rem;
            }
          }

          .slick-next {
            position: absolute;
            top: 45%;
            right: -25%;
            font-size: 3rem;
            color: $color-main;
            cursor: pointer;
            @include media-breakpoint-down(sm) {
              right: -15%;
            }
            @include media-breakpoint-down(xs) {
              font-size: 2rem;
            }
          }
        }
      }
    }

    .slick-slider {
      .slick-dots {
        position: absolute;
        bottom: -50%;
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        @include media-breakpoint-down(sm) {
          bottom: -60%;
        }

        li {
          position: relative;
          display: inline-block;
          height: 20px;
          width: 20px;
          margin: 0 5px;
          padding: 0;
          cursor: pointer;

          button {
            border-radius: 100%;
            display: block;
            height: 15px;
            width: 15px;
            line-height: 0px;
            font-size: 0px;
            color: $color-light-grey;
            background-color: $color-light-grey;
            border: 4px solid $color-light-grey;
            padding: 5px;
            cursor: pointer;

            &:hover {
              color: $color-main;
              background-color: $color-main;
              border: 4px solid $color-main;
            }
          }

          &.slick-active button {
            color: $color-main;
            background-color: transparent;
            border: 4px solid $color-main;
          }
        }
      }
    }
  }
}
