// Fix Chrome: focus buttons
button:focus {
  outline: 0;
}

// Cookie-Consent override
.cc-window.cc-banner.cc-type-opt-in {
  padding: 2rem 1rem !important;

  a.cc-btn.cc-allow {
    &:hover {
      color: $color-secondary !important;
      text-decoration: underline !important;
    }
  }
}
