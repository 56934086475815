.testimonialSection {
  background-color: $white;

  .testimonialSectionContainer {
    padding-top: 4rem;
    padding-bottom: 4rem;
    text-align: center;

    .testimonialImage {
      display: block;
      border-radius: 50%;
      background-position: center;
      background-size: cover;
      margin-right: auto;
      margin-left: auto;
      margin-bottom: 3rem;
      @include media-breakpoint-up(lg) {
        width: 10vw;
        height: 10vw;
      }
      @include media-breakpoint-down(md) {
        width: 15vw;
        height: 15vw;
      }
      @include media-breakpoint-down(sm) {
        width: 20vw;
        height: 20vw;
      }
    }

    .testimonialContent {
      margin-bottom: 3rem;
      font-size: $font-size-h1-sub;
      line-height: $font-size-head;
      @include media-breakpoint-up(lg) {
        padding: 0px 5rem;
      }

      i.fas {
        margin: 0px 10px;
        color: $color-main;
      }
    }

    strong {
      display: block;
      font-size: $font-size-stronghead;
      font-weight: normal;

      &.testimonialName {
        color: $color-main;
      }
    }

    a {
      color: $color-light-grey;
    }
  }
}
