.contentSection.contentSectionTwoThirds {
  background-color: $color-very-light-grey;

  .contentSectionContainer {
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-top: 5rem;
    margin-bottom: 5rem;

    p {
      margin-bottom: 0px;
    }

    &.contentSectionContainerColRight {
      @include media-breakpoint-up(lg) {
        border-left: 0.25rem solid $color-main;
        padding-left: 3rem;
      }

      strong {
        margin-top: 1rem;
        display: block;
      }
    }

    &.contentSectionContainerColLeft {
      @include media-breakpoint-up(lg) {
        padding-right: 3rem;
      }
      @include media-breakpoint-down(md) {
        margin-bottom: 0rem;
      }
    }

    .contentSectionContent {
      font-size: $font-size-bigger;
      margin: 1rem 0rem 2rem;

      strong {
        color: $color-main;
      }

      h4 {
        font-size: $font-size-h1-sub;
      }
    }

    a.buttonLink {
      min-width: 150px;
      text-align: center;
    }

    ul {
      font-size: $font-size-bigger;
      list-style-type: none;
      margin: 0px;
      padding: 0px;

      li {
        padding-left: 1rem;
        text-indent: -1rem;

        &:before {
          content: "\25E6";
          font-family: "fontawesome";
          color: $color-main;
          width: 1rem;
          font-weight: bolder;
          margin-right: 0.25rem;
        }
      }
    }
  }
}
