.scrollAnkerContainer {
  transition: all 0.2s ease;
  margin-bottom: 8rem;
  margin-bottom: 8rem;
  @include media-breakpoint-up(sm) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  .singleScrollAnker {
    text-align: center;
    @include media-breakpoint-down(md) {
      margin-bottom: 3rem;
    }

    a {
      &:hover {
        text-decoration: none;

        i, strong {
          transition: all 0.2s ease;
          color: $color-secondary;
        }
      }
    }

    .singleScrollAnkerIcon {
      font-size: 4rem;
      line-height: 5rem;
      display: block;
      color: $color-main;
    }

    .singleScrollAnkerHeadline {
      margin-top: 0.75rem;
      display: block;
      color: $color-main;
      font-size: $font-size-stronghead;
    }
  }
}
