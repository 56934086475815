.lastProjectsContainerWrapper {
  padding: 4rem 0rem;
  background-color: $color-very-light-grey;

  h2 {
    text-align: center;
  }

  .lastProjectsContainer {
    padding: 0px 3vw;
    @include media-breakpoint-up(lg) {
      height: 32.5vw;
      max-height: 450px;
    }
    @include media-breakpoint-down(md) {
      height: 37.5vw;
    }
    @include media-breakpoint-down(sm) {
      height: 65vw;
    }
    @include media-breakpoint-down(xs) {
      height: 100vw;
      padding: 0px;
    }
    @media (max-width: 475px) {
      height: 120vw;
    }
    @media (max-width: 399px) {
      height: 150vw;
    }
    margin-bottom: 3rem;

    .singleLastProject {
      transition: all 0.2s ease;
      padding: 15px;

      .singleLastProjectContent {
        padding-top: 5.5vw;
        @include media-breakpoint-up(md) {
          padding-left: 0px;
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: 2.5rem;
        }

        .singleLastProjectDate {
          display: block;
          font-size: $font-size-h1-sub;
          color: $color-light-grey;
        }

        .singleLastProjectHeadline {
          display: block;
          font-weight: normal;
          color: $color-main;
          font-size: $font-size-slider-head;
          line-height: 2rem;
          margin-bottom: 0.25rem;
        }
      }

      .singleLastProjectDevice {
        @include media-breakpoint-down(xs) {
          max-width: 50vw;
          margin-left: auto;
          margin-right: auto;
        }

        .referenceHeaderImageSectionTvContainer {
          @include media-breakpoint-down(md) {
            margin-top: 7.5vw;
          }
          @include media-breakpoint-down(sm) {
            margin-top: 15vw;
          }
        }

        .referenceHeaderImageSectionMonitorContainer {
          @include media-breakpoint-down(md) {
            margin-top: 7.5vw;
          }
          @include media-breakpoint-down(sm) {
            margin-top: 15vw;
          }
        }

        .referenceHeaderImageSectionPhoneContainer {
          @include media-breakpoint-down(md) {
            margin-top: 7.5vw;
          }
          @include media-breakpoint-down(sm) {
            margin-top: 15vw;
          }
        }
      }
    }

    .slick-prev {
      position: absolute;
      top: 45%;
      left: -2%;
      font-size: 3rem;
      color: $color-main;
      z-index: 999;
      cursor: pointer;
      @include media-breakpoint-down(lg) {
        left: 0%;
      }
      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
        left: -2%;
      }
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    .slick-next {
      position: absolute;
      top: 45%;
      right: -2%;
      font-size: 3rem;
      color: $color-main;
      z-index: 999;
      cursor: pointer;
      @include media-breakpoint-down(lg) {
        right: 0%;
      }
      @include media-breakpoint-down(sm) {
        font-size: 1.5rem;
        right: -2%;
      }
      @include media-breakpoint-down(xs) {
        display: none;
      }
    }

    .slick-dots {
      position: absolute;
      bottom: -3rem;
      list-style: none;
      display: block;
      text-align: center;
      padding: 0;
      margin: 0;
      width: 95%;
      @include media-breakpoint-down(sm) {
        width: 90%;
      }

      li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;

        button {
          border-radius: 100%;
          display: block;
          height: 15px;
          width: 15px;
          line-height: 0px;
          font-size: 0px;
          color: $color-light-grey;
          background-color: $color-light-grey;
          border: 4px solid $color-light-grey;
          padding: 5px;
          cursor: pointer;

          &:hover {
            color: $color-main;
            background-color: $color-main;
            border: 4px solid $color-main;
          }
        }

        &.slick-active button {
          color: $color-main;
          background-color: transparent;
          border: 4px solid $color-main;
        }
      }
    }
  }
}
