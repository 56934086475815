.otherProjectsContainerWrapper {
  padding: 4rem 0rem;
  background-color: $color-very-light-grey;

  h2 {
    text-align: center;
  }

  .otherProjectsContainer {
    margin-top: 3rem;

    .singleOtherProject {
      transition: all 0.2s ease;
      padding: 15px 2rem;

      .singleOtherProjectContent {
        padding-top: 3vw;
        @include media-breakpoint-up(md) {
          padding-left: 0px;
        }
        @include media-breakpoint-down(sm) {
          margin-bottom: 2.5rem;
        }

        .singleOtherProjectDate {
          display: block;
          color: $color-light-grey;
        }

        .singleOtherProjectHeadline {
          display: block;
          font-weight: normal;
          color: $color-main;
          font-size: $font-size-alt-head;
          line-height: $font-size-h1-sub;
          margin-bottom: 0.25rem;
        }
      }
    }
  }
}
