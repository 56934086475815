@mixin defaulttransition($duration: 300ms, $what: all) {
  transition: $what $duration ease-in-out;
}

@mixin keepaspectratio($percentageratio : 56.25%) {
  &::before {
    content: "";
    display: block;
    padding-top: $percentageratio;
    pointer-events: none;
  }
}

@mixin floating-boxshadow() {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

@mixin buttonlinkstyling($inverted: false) {
  @if ($inverted == false) {
    @include defaulttransition();
    display: inline-block;
    padding: 7.5px 30px;
    background-color: $color-main;
    color: $white;
    font-size: $font-size-body;
    box-shadow: inset 0px 0px 15px 10px rgba(149, 23, 49, 1);

    &:hover {
      background-color: $color-secondary;
      text-decoration: none;
      box-shadow: none;
    }

    &:active {
      background-color: $color-tertiary;
      text-decoration: none;
      box-shadow: none;
    }
  }

  @if ($inverted == true) {
    @include defaulttransition();
    box-shadow: none;
    display: inline-block;
    padding: 7.5px 30px;
    background-color: transparent;
    color: $white;
    font-size: $font-size-body;
    border: 2px solid $white;

    &:hover {
      background-color: $white;
      color: $color-main;
      border-color: transparent;
      text-decoration: none;
    }
  }
}
