.mainIntroSection {
  margin: 5rem 0rem;

  .introheadline {
    text-align: center;
    font-size: $font-size-head;
    color: $color-main;
    @include media-breakpoint-down(sm) {
      hyphens: auto;
    }
  }

  .introsubheadline {
    display: block;
    text-align: center;
    font-size: $font-size-h1-sub;
    font-weight: 300;
    color: $color-light-grey;
  }

  .introtext {
    margin-top: 2rem;
    padding: 0px;
    font-size: $font-size-intro;
  }

  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;

    li {
      padding-left: 1rem;
      text-indent: -1rem;

      &:before {
        content: "\25E6";
        font-family: "fontawesome";
        color: $color-main;
        width: 1rem;
        font-weight: bolder;
        margin-right: 0.4rem;
      }
    }
  }
}
