.technologiesContainerWrapper {
  padding: 4rem 0rem;
  @include media-breakpoint-up(sm) {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  h2 {
    text-align: center;
  }

  .technologiesContainer {
    margin-top: 3rem;

    .singleTechnology {
      transition: all 0.2s ease;
      padding: 15px;

      .technologySizer {
        @include defaulttransition();
        padding-top: 100%;
        border-radius: 5px;
        border: 1px solid $color-light-grey;
        background-position: center;
        background-size: cover;
        filter: grayscale(100%);

        &:hover {
          -webkit-filter: none;
          filter: none;
        }
      }
    }
  }
}
