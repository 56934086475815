/*!
 * Ewerk Corporate Page
 * Copyright 2018 Ewerk GmbH, All rights reserved.
 * Visit Ewerk.de
 */

@import "../../../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../../../node_modules/font-awesome/scss/font-awesome.scss";
@import "../../../../node_modules/slick-carousel/slick/slick.scss";

@import "common/variables";
@import "common/mixins";
@import "common/global";
@import "common/fixes";

@import "components/elements";
@import "components/textstyles";

@import "layouts/benefits";
@import "layouts/clients";
@import "layouts/contact-form";
@import "layouts/contact-section";
@import "layouts/content-section";
@import "layouts/content-section-accordion";
@import "layouts/content-section-two-cols";
@import "layouts/content-section-two-thirds";
@import "layouts/content-half-element";
@import "layouts/employees";
@import "layouts/footer";
@import "layouts/frontpage-jobs";
@import "layouts/frontpage-slider";
@import "layouts/fullwidth-gmaps";
@import "layouts/header";
@import "layouts/headerimage";
@import "layouts/homepage-header";
@import "layouts/intro-section";
@import "layouts/jobs";
@import "layouts/last-projects-slider";
@import "layouts/other-projects";
@import "layouts/reference-header-section";
@import "layouts/single-jobs";
@import "layouts/scroll-anker";
@import "layouts/technology";
@import "layouts/testimonial-section";

