.contactSection {
  background: $default-gradient;
  color: $white;
  padding: 50px 0px;
  text-align: center;

  strong {
    font-family: $neo-sans;
    font-size: $font-size-h1-sub;
    font-weight: normal;
    line-height: 3rem;

    @include media-breakpoint-down(md) {
      line-height: 2rem;
    }
  }

  @include media-breakpoint-down(md) {
    a.buttonLinkInvert {
      margin-top: 1rem;
    }
  }
}
