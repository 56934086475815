.employeesContainer {
  padding: 4rem 0px;
  background-color: $color-very-light-grey;

  h2 {
    text-align: center;
  }

  .singleEmployee {
    margin-top: 3rem;

    .singleEmployeeImageContainer {
      .singleEmployeeImage {
        background-position: center;
        background-size: cover;
        width: 100%;
        padding-top: 100%;
      }
    }

    .singleEmployeeContent {
      .singleEmployeeContentWrapper {
        strong, span {
          display: block;
          font-weight: normal;
        }

        .singleEmployeeName {
          font-size: $font-size-stronghead;
          color: $color-main;
        }

        .singleEmployeePosition {
          color: $color-light-grey;
        }

        .singleEmployeeTasks {
          font-style: italic;
          @include media-breakpoint-down(sm) {
            display: none;
          }
        }

        .singleEmployeeMail {
          margin-top: 0.8rem;
        }
      }
    }
  }
}
