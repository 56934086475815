.footer {
  background-color: $color-dark-grey;
  color: $white;
  border-bottom: 5px solid $color-main;

  .row.footerCols {
    strong {
      font-size: $font-size-stronghead;
    }

    hr {
      margin-top: 0.75rem;
      margin-bottom: 0.75rem;
      border-top: 2px solid $white;
      @include media-breakpoint-down(md) {
        width: 65%;
      }
    }

    .first-col {
      padding: 0px 30px;
      margin-bottom: 50px;
      color: $color-light-grey;

      .footer-logo {
        max-width: 50%;
        margin-bottom: 25px;
        @include media-breakpoint-down(md) {
          width: 30vw;
        }
      }

      .socialIcons {
        // Solange keine Kanäle, keine Symbole!
        display: none;
        margin-top: 15px;

        a {
          transition: color 0.2s ease;
          color: $color-light-grey;

          &:hover {
            color: $white;
            text-decoration: none;
          }

          i.fab {
            font-size: $font-size-icon;
            margin-right: 10px;
          }
        }
      }

      .footerDescriptionText {
        @include media-breakpoint-down(md) {
          display: none;
        }
      }
    }

    .second-col {
      padding: 0px 30px;
      margin-bottom: 50px;

      ul {
        list-style-type: none;
        padding-left: 0px;

        li {
          a {
            color: $white;

            &[href="/"] {
              display: none;
            }
          }
        }
      }
    }

    .third-col {
      padding: 0px 30px;
      margin-bottom: 50px;
      font-size: 1rem;

      #footerMap {
        width: 100%;
        height: 215px;
      }

      .address {
        color: $white;
      }

      .contactData {
        font-weight: normal;
        color: $color-light-grey;

        a {
          color: $color-light-grey;

          &:hover {
            color: $color-light-grey;
          }
        }
      }

      .socialIcons {
        display: none;
      }

      @include media-breakpoint-down(md) {
        a.buttonLink {
          display: none;
        }
        .socialIcons {
          //display: inline-block;
          text-align: center;
          margin-top: 15px;

          a {
            transition: color 0.2s ease;
            color: $color-light-grey;

            &:hover {
              color: $white;
              text-decoration: none;
            }

            i.fab {
              font-size: $font-size-icon;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  .row.footerCredits {
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
    }

    p {
      color: $white;
      width: 100%;
      text-align: center;

      span {
        font-weight: bold;

        b {
          color: $color-main;
        }
      }

      i {
        font-style: normal;
        font-weight: 400;
        color: $color-light-grey;
      }

    }
  }

  @include media-breakpoint-down(md) {
    text-align: center;
    padding-top: 75px;
    padding-bottom: 25px;
  }
  @include media-breakpoint-up(lg) {
    padding: 100px 75px 25px;
  }
}
