.contactFormContainer {
  strong {
    &.contactformLeftInterestsHeadline,
    &.contactformLeftCoffeeHeadline,
    &.contactformRightHeadline {
      margin: 2rem 0rem;
      display: block;
      font-size: $font-size-stronghead;
      color: $color-light-grey;
    }
  }

  .contactFormError {
    color: $color-main;
    background-color: rgba(255, 0, 0, 0.3);
    display: block;
    width: 100%;
    padding: 1rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  .contactFormSuccess {
    color: rgb(40, 80, 40);
    background-color: rgba(75, 180, 65, 0.3);
    display: block;
    width: 100%;
    padding: 1rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  input[type=checkbox] {
    display: none;
  }

  label {
    transition: all 0.2s ease;

    &.alternateCheckbox {
      width: 1.25rem;
      height: 1.25rem;
      background-color: $color-very-light-grey;
      border: 5px solid $color-very-light-grey;
      margin-bottom: 0px;
      margin-right: 0.5rem;
      cursor: pointer;

      &.alternateCheckboxChecked {
        background-color: $color-main;
      }
    }

    &.formCheckboxLabel {
      display: inline;
      line-height: 1.25rem;
      vertical-align: 15%;
    }
  }

  select {
    width: 100%;
    margin-bottom: 0.5rem;
    padding: 0rem 0.5rem;
    line-height: 3rem;
    height: 3rem;
    background-color: $white;
    border: 1px solid $color-light-grey;
  }

  .contactformLeftInterestsHeadline,
  .contactformLeftCoffeeHeadline {
    font-weight: normal;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 2rem;
  }

  .optionCheckbox {
    margin-bottom: .5rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 1.5rem;
    }
  }

  .optionRadio {
    margin-bottom: .5rem;
    @include media-breakpoint-up(md) {
      margin-bottom: 0.75rem;
    }
  }

  input, textarea {
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border: 1px solid $color-light-grey;
  }

  input[type="radio"] {
    width: auto;
    margin-right: 0.5rem;
    min-height: 0rem;
  }

  input {
    margin-bottom: 0.5rem;
    min-height: 3rem;

    &#plz {
      width: 35%;
    }

    &#ort {
      width: 60%;
      position: absolute;
      right: 15px;
    }
  }

  textarea {
    min-height: 11.75rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
  }

  .optionError {
    input, textarea {
      border: 2px solid $color-main;
    }

    label {
      color: $color-main;
    }
  }

  .contactformRightHeadline {
    font-weight: normal;
  }

  @include media-breakpoint-down(md) {
    margin-bottom: 2rem;
  }

  .optionSubmit {
    width: 100%;
    text-align: center;

    .optionSubmitButton {
      @include buttonlinkstyling();
    }
  }

  .confirmationCheckbox {
    display: none !important;
  }
}
