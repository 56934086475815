// Fullwidth-Gmaps
.fullwidthGoogleMaps {
  display: block;
  position: relative;
  height: 50vh;
  padding: 0px;
  margin-top: 4rem;
  background-size: cover;
  background-position: center;
  background-color: $color-main;

  .overlay {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: $color-main;

    .text {
      color: white;
      font-size: 1.25rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      text-align: center;
    }
  }

  &:hover .overlay {
    opacity: .95;
  }

  iframe {
    width: 100% !important;
    height: 100% !important;
  }
}
