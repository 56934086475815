nav.navbar {
  background-color: $white;
  color: $color-light-grey;
  border-top: 2px solid $color-main;
  border-bottom: 1px solid $white;
  transition: all 0.5s ease;
  font-family: $neo-sans;
  font-size: $font-size-navigation;
  box-shadow: 0px 5px 30px 3px rgba(0, 0, 0, 0.1);
  max-height: 100vh;

  li.nav-item {
    a.nav-link {
      padding-left: 0.75rem;
      padding-right: 0.75rem;
      transition: all 0.2s ease;
      color: $color-light-grey;

      &:hover {
        color: $color-secondary;
        text-decoration: none;
      }
    }

    &.active {
      a.nav-link {
        color: $color-main;
      }
    }

    a.nav-link[href="/impressum"],
    a.nav-link[href="/datenschutz"],
    a.nav-link[href="/kontakt"] {
      display: none;
    }
  }

  a.navbar-brand {
    margin: 0px 10px;

    img {
      width: auto;
      height: 45px;
      transition: all 250ms ease;

      @include media-breakpoint-down(sm) {
        height: 25px;
      }
    }

    &.navbar-brand-negative {
      display: none;
    }
  }

  .navbar-toggler {
    cursor: pointer;
    padding: 10px 35px 16px 0;
    border-bottom: none;

    span,
    span::before,
    span::after {
      cursor: pointer;
      border-radius: 1px;
      height: 3px;
      width: 28px;
      background: $black;
      position: absolute;
      display: block;
      content: "";
      transition: all 200ms ease-in-out;
    }

    span {
      &::before {
        top: -8px;
      }

      &::after {
        bottom: -8px;
      }
    }

    &[aria-expanded=true] {
      span {
        background: transparent;

        &::before,
        &::after {
          top: 0;
        }

        &::before {
          transform: rotate(45deg);
        }

        &::after {
          transform: rotate(-45deg);
        }
      }
    }
  }

  button.navbar-caller {
    padding: 0px;
    height: 2rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;

    a.navbar-caller-icon {
      i {
        font-size: $font-size-icon;
        line-height: $font-size-icon;
      }

      color: $black;

      &:hover {
        text-decoration: none;
        color: $black;
      }
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }

  a.buttonLink {
    @include media-breakpoint-up(lg) {
      margin-left: 1rem;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 100px;
    padding: 0px 75px;

    &.navbar-homepage {
      height: 80px;

      &:not(.scrollHeader) {
        .navbar-brand-positive {
          display: none;
        }

        .navbar-brand-negative {
          display: initial;
        }
      }

      &.scrollHeader {
        .navbar-brand-positive {
          display: initial;
        }

        .navbar-brand-negative {
          display: none;
        }
      }
    }

    &.scrollHeader {
      height: 65px;

      a.navbar-brand {
        img {
          height: 30px;

          @include media-breakpoint-down(sm) {
            height: 20px;
          }
        }
      }
    }

    // Homepage-Header
    &:not(.scrollHeader) {
      &.navbar-homepage {
        background-color: transparent;
        color: $white;
        border-top: none;
        border-bottom: 1px solid $white;

        a.navbar-brand {
          img {
            height: 30px;
            opacity: 0.5;
          }
        }

        li.nav-item {
          &.active {
            a.nav-link {
              color: $white;
            }
          }
        }

        a.buttonLink {
          @include buttonlinkstyling(true);

          border: 2px solid $white;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .navbar-collapse {
      text-align: center;

      .navbar-nav {
        margin-top: 2rem;

        li.nav-item {
          margin-bottom: 0.5rem;
        }
      }

      .buttonLink {
        margin-bottom: 3rem;
      }
    }
  }
}
