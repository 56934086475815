a {
  color: $color-main;

  &:hover {
    color: $color-secondary;
  }

  &:focus {
    color: $color-tertiary;
  }
}

.main {
  padding-bottom: 3rem;

  ul {
    list-style-type: none;
    margin: 0px;
    padding: 0px;
    padding-left: 1rem;

    li {
      &:before {
        content: "\25E6";
        font-family: "fontawesome";
        color: $color-main;
        width: 1rem;
        font-weight: bolder;
        margin-right: 0.35rem;
        margin-left: -1rem;
      }
    }
  }
}

.privacy-msg {
  height: 100%;
  width: 100%;

  .privacy-msg-content {
    background-color: $white;
    width: 200px;
    padding: 15px;
    text-align: center;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
