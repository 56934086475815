.frontPageSlider {
  width: 100%;

  .frontPageSliderPage {
    @include media-breakpoint-up(lg) {
      height: 35vh;
    }
    @include media-breakpoint-down(md) {
      height: 50vw;
      max-height: 50vh;
    }
    position: relative;
    width: 100%;
    background-position: center;
    background-size: cover;
  }

  .slick-dots {
    position: absolute;
    bottom: 25px;
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;

    li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      button {
        border-radius: 100%;
        display: block;
        height: 15px;
        width: 15px;
        line-height: 0px;
        font-size: 0px;
        color: $color-light-grey;
        background-color: $color-light-grey;
        border: 4px solid $color-light-grey;
        padding: 5px;
        cursor: pointer;

        &:hover {
          color: $color-main;
          background-color: $color-main;
          border: 4px solid $color-main;
        }
      }

      &.slick-active button {
        color: $color-main;
        background-color: transparent;
        border: 4px solid $color-main;
      }
    }
  }
}
