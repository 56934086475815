.jobListing {
  margin-top: 3rem;
  margin-bottom: 8rem;

  .jobListingContainer {
    margin-bottom: 3rem;

    h2 {
      font-size: $font-size-h1-sub;
    }
  }
}

.jobPracticumStudent {
  padding-top: 4rem;
  padding-bottom: 4rem;
  background-color: $color-very-light-grey;

  .jobPracticumStudentLeft {
    .jobPracticumStudentLeftHeadline {
      color: $color-main;
    }

    @include media-breakpoint-up(lg) {
      border-right: 2px solid $color-main;
      padding: 4rem;
      padding-left: 0rem;
    }
  }

  .jobPracticumStudentRight {
    .jobPracticumStudentRightHeadline {
      color: $color-main;
    }

    @include media-breakpoint-up(lg) {
      border-left: 2px solid $color-main;
      padding: 4rem;
      padding-right: 0rem;
    }
  }
}
