//
//
// Variablen
//
// 


//
// Colors system
//
$white: #fff !default;
$color-main: #bd0034;
$color-secondary: #71001f;
$color-tertiary: #951731;
$color-very-light-grey: #F0F0F0;
$color-light-grey: #ADADAD;
$color-dark-grey: #3D3D3D;
$color-font-grey: #444444;
$black: #000 !default;

// Gradients
$default-gradient: linear-gradient(135deg, rgba(149, 23, 49, 1) 15%, rgba(189, 0, 52, 1) 75%);
$default-gradient-transparent: linear-gradient(135deg, rgba(149, 23, 49, 1) 10%, rgba(189, 0, 52, 1) 35%, rgba(189, 0, 52, 0) 100%);

//
// Fonts
//
$neo-sans: 'Neo Sans Std', sans-serif;

//
// Font-Sizes
//
$font-size-head: 2.25rem;
$font-size-icon: 2rem;
$font-size-slider-head: 1.75rem;
$font-size-h1-sub: 1.5rem;
$font-size-alt-head: 1.25rem;
$font-size-navigation: 1.125rem;
$font-size-stronghead: 1.125rem;
$font-size-intro: 1rem;
$font-size-bigger: 1rem;
$font-size-body: 0.9rem;
$font-size-small: 0.65rem;
