.contentSection.contentSectionAccordion {
  background-color: $color-very-light-grey;
  padding: 7.5vw 10vw;

  .contentSectionContainer {
    .contentSectionHeadline {
      text-align: center;
    }

    .contentSectionContent {
      font-size: $font-size-bigger;
      margin: 2rem 0rem;

      strong {
        color: $color-main;
      }
    }

    a.buttonLink {
      min-width: 150px;
      text-align: center;
    }
  }

  .contentSectionAccordionContainer {
    width: 100%;

    .card {
      padding: 0px;

      .card-header {
        padding: 0px;
        border: none;

        button {
          width: 100%;
          height: 3rem;
          color: $color-main;
          font-size: 1.25rem;
          background-color: $white;
          transition: all 0.4s ease;

          &:hover {
            background-color: $color-main;
            color: $white;
            text-decoration: none;
          }

          &:focus {
            text-decoration: none;
          }

          &[aria-expanded="true"] {
            background-color: $color-secondary;
            color: $white;
            text-decoration: none;

            &:hover {
              background-color: $color-main;
            }
          }

          i {
            margin-right: 5px;
          }
        }
      }

      .card-body {
        background-color: $color-very-light-grey;

        p {
          margin-bottom: 0px;
        }
      }
    }
  }
}
