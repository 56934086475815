.contentSection.contentSectionDouble {
  background-color: $color-very-light-grey;

  .contentSectionImage {
    background-position: center;
    background-size: cover;
    @include media-breakpoint-down(md) {
      padding-top: 35vw;
    }
  }

  .contentSectionContainer {
    padding: 7.5vw 10vw;
    @include media-breakpoint-up(lg) {
      min-height: 65vh;
    }
    @include media-breakpoint-down(md) {
      min-height: 20vh;
    }

    .contentSectionContent {
      font-size: $font-size-bigger;
      margin: 2rem 0rem;

      strong {
        color: $color-main;
      }
    }

    a.buttonLink {
      min-width: 150px;
      text-align: center;
    }
  }
}
